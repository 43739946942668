*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
html {
  font-family: Roboto, sans-serif;
}
body {
  background-color: #F9F9F9;
}
